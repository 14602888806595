import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Button from "../UI/Button"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: animate-overlay-in 0.4s forwards ease-in-out;
  @keyframes animate-overlay-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  color: #fff;
  background-color: #ee2a7b;
  background-image: linear-gradient(45deg, #ee2a7b, #662d91);
  width: 830px;
  height: 440px;
  padding: 20px;
  position: relative;
  border-radius: 80px;
  display: flex;
  > :first-child,
  > :last-child {
    position: absolute;
    bottom: 0;
  }
  @media (max-width: 840px) {
    > :first-child,
    > :last-child {
      display: none;
    }
  }
  > :last-child {
    right: 60px;
  }
  transform: translateY(50vw);
  animation: animate-in 0.4s forwards ease-in-out;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.4);
  @keyframes animate-in {
    from {
      transform: translateY(50vw);
    }
    to {
      transform: translateY(0);
    }
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  > * {
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: inherit;
    width: 100%;
    display: flex;
    > * {
      width: 100%;
    }
  }
`

const Title = styled.h2`
  font-size: 3.5rem;
`

const Guest = styled.div`
  span {
    text-decoration: underline;
  }
`

interface IProps {
  dismiss: () => void
}

const LoginModal = (props: IProps) => (
  <Overlay onClick={props.dismiss}>
    <Wrapper onClick={e => e.stopPropagation()}>
      <StaticImage
        src="../../images/characters/will.png"
        alt="Will"
        height={380}
        width={254}
        placeholder="blurred"
      />
      <Content>
        <Title>Log in as</Title>
        <a href="https://nightzookeeper.com/edu/zoo/login">
          <Button theme="primary" size="regular">
            Child
          </Button>
        </a>
        <a href="https://dashboard.nightzookeeper.com/login">
          <Button theme="confirm" size="regular">
            Parent
          </Button>
        </a>
        <a href="https://nightzookeeper.com/edu/dashboard/login">
          <Button theme="confirm" size="regular">
            Teacher
          </Button>
        </a>
        <a href="https://nightzookeeper.com/edu/commenter/login">
          <Guest>
            Commenting as a <span>guest?</span>
          </Guest>
        </a>
      </Content>
      <StaticImage
        src="../../images/characters/riya.png"
        alt="Riya"
        height={350}
        width={160}
        placeholder="blurred"
      />
    </Wrapper>
  </Overlay>
)

export default LoginModal

import { graphql, useStaticQuery } from "gatsby"
import React, { ReactElement } from "react"
import { Helmet } from "react-helmet"

interface IProps {
  description?: string
  lang?: string
  image?: string
  url?: string
  canonical?: string
  meta?: { name: string; content: string }[]
  title?: string
  titleTemplate?: string
  noIndex?: boolean
  links?: any[]
  children?: ReactElement
}

const SEO = (props: IProps) => {
  const {
    children,
    description,
    lang,
    meta,
    title,
    titleTemplate,
    image,
    canonical,
    url,
    noIndex,
    links = [],
  } = props

  const { site, strapiHomepage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            keywords
            siteUrl
          }
        }
        strapiHomepage {
          SEO {
            title
            description
            url
            noIndex
            image {
              url
            }
            canonical
          }
        }
      }
    `
  )

  const strapiSEODefaults = strapiHomepage.SEO || {}
  const metaDescription =
    description ||
    strapiSEODefaults.description ||
    site.siteMetadata.description
  const metaImage =
    image ||
    (strapiSEODefaults.image && strapiSEODefaults.image.url) ||
    site.siteMetadata.image
  const defaultTitle = strapiSEODefaults.title || site.siteMetadata?.title
  const canonicalUrl =
    canonical || strapiSEODefaults.canonical || site.siteMetadata.siteUrl
  const metaUrl = url || canonical || strapiSEODefaults.url || strapiSEODefaults.canonical || site.siteMetadata.siteUrl
  const metaNoIndex = strapiSEODefaults.noIndex || noIndex

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={
        canonical
          ? [
              ...links,
              { rel: "canonical", key: canonicalUrl, href: canonicalUrl },
            ]
          : [...links]
      }
      title={title}
      titleTemplate={
        titleTemplate || (defaultTitle ? `%s | ${defaultTitle}` : title)
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `title`,
          content: defaultTitle ? `${title} | ${defaultTitle}` : title,
        },
        {
          property: `og:title`,
          content: defaultTitle ? `${title} | ${defaultTitle}` : title,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: defaultTitle ? `${title} | ${defaultTitle}` : title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        metaNoIndex && {
          name: "robots",
          content: "noindex",
        },
      ]
        .concat(meta)
        .filter(d => d)}
    >
      { children }
    </Helmet>
  )
}

export const getSEOProps = (props): IProps => {
  const strapiSEO = props || {}
  return {
    description: strapiSEO.description,
    image: strapiSEO.image && strapiSEO.image.url,
    title: strapiSEO.title,
    canonical: strapiSEO.canonical,
    url: strapiSEO.url,
    noIndex: strapiSEO.noIndex,
  }
}

SEO.defaultProps = {
  children: null,
  lang: `en`,
  meta: [],
  title: "Home",
  titleTemplate: null,
  canonical: null,
  description: ``,
  image: null,
  url: null,
  noIndex: false,
  links: [],
}

export default SEO
